import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import storeSistema from '@/store/storeSistema';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import {
  ITituloFinanceiro, ITituloFinanceiroAnexo, ITituloFinanceiroBoleto, ITituloFinanceiroCheque, ITituloFinanceiroDetalhamento, ITituloFinanceiroDocumentoFiscal, ITituloFinanceiroMarcador, ITituloFinanceiroRepasse,
} from '@/models/Entidades/Financeiro/TitulosFinanceiros/ITituloFinanceiro';
import { IDTOTituloFinanceiroCentroCusto } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroCentroCusto';
import { IRetornoArquivo } from '@/core/models/IRetornoArquivo';
import { IDTOTituloFinanceiroRegistroAtividade } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroRegistroAtividade';
import { ETipoDado } from '@/core/models/Enumeradores/ETipoDado';
import { EOperadorLogico } from '@/core/models/Enumeradores/EOperadorLogico';
import { ECondicao } from '@/core/models/Enumeradores/ECondicao';
import { EStatusTituloFinanceiro } from '@/models/Enumeradores/Financeiro/EStatusTituloFinanceiro';
import { EMeioPagamento } from '@/models/Enumeradores/Cadastros/Financeiro/EMeioPagamento';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';
import { IParametrosConsultaTituloFinanceiro } from '@/models/ParametrosRequisicao/Financeiro/IParametrosConsultaTituloFinanceiro';
import { IDTOTituloFinanceiroAnexoInserir } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroAnexoInserir';
import { IDTOTituloFinanceiroCalendario } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroCalendario';
import { IDTORemessaPagamentos } from '@/models/DTO/Financeiro/RemessasPagamentos/IDTORemessaPagamentos';
import { IDTOTituloFinanceiroObterDetalhamentoVariosTitulos } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroObterDetalhamentoVariosTitulos';
import { IDTOTituloFinanceiroDetalhamento } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroDetalhamento';

/**
 * Serviço de Títulos Financeiros
 * Fornece todas regras de negócios e lógicas relacionado a título financeiro.
 */

class ServicoTituloFinanceiroPagar {
    endPoint = 'titulos-financeiros-pagar';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterPropriedadesConsulta(empresa: number): Promise<IPropriedadeConsulta[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/propriedades-consulta/empresa/${empresa}`);
      return result.data;
    }

    public async buscarTitulos(parametrosConsulta: IParametrosConsultaTituloFinanceiro, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
      let parametrosAdicionais = '';
      parametrosAdicionais = this.apiERP.obterParametroEmpresas(parametrosConsulta.empresas);

      if (filtros !== undefined) {
        const jsonFiltros = JSON.stringify(filtros);
        parametrosAdicionais += `&Filtros=${jsonFiltros}`;
      }
      if (parametrosConsulta.numeroPagina !== undefined && parametrosConsulta.numeroPagina > 0) {
        parametrosAdicionais += `&NumeroPagina=${parametrosConsulta.numeroPagina}`;
      }
      if (parametrosConsulta.qtdeRegistrosPagina !== undefined && parametrosConsulta.qtdeRegistrosPagina > 0) {
        parametrosAdicionais += `&QtdeRegistrosPagina=${parametrosConsulta.qtdeRegistrosPagina}`;
      }
      if (parametrosConsulta.qtdeRegistrosTotal !== undefined && parametrosConsulta.qtdeRegistrosTotal > 0) {
        parametrosAdicionais += `&QtdeRegistrosTotal=${parametrosConsulta.qtdeRegistrosTotal}`;
      }
      if (parametrosConsulta.ordenacao !== undefined) {
        parametrosConsulta.ordenacao.forEach((item) => {
          parametrosAdicionais += `&Ordenacao=${item}`;
        });
      }
      if (parametrosConsulta.codigosSelecionados !== undefined) {
        parametrosConsulta.codigosSelecionados.forEach((codigo) => {
          parametrosAdicionais += `&CodigosSelecionados=${codigo}`;
        });
      }

      if (parametrosConsulta.omitirTitulosRepassados !== undefined) {
        if (parametrosConsulta.omitirTitulosRepassados) {
          parametrosAdicionais += '&OmitirTitulosRepassados=true';
        }
      }

      if (parametrosConsulta.apenasTitulosPendentes !== undefined) {
        if (parametrosConsulta.apenasTitulosPendentes) {
          parametrosAdicionais += '&ApenasTitulosPendentes=true';
        }
      }

      if (parametrosConsulta.valorTotalTituloEspecifico !== undefined) {
        if (parametrosConsulta.valorTotalTituloEspecifico > 0) {
          parametrosAdicionais += `&ValorTotalTituloEspecifico=${parametrosConsulta.valorTotalTituloEspecifico}`;
        }
      }

      if (parametrosConsulta.valorBuscaRapida !== undefined) {
        parametrosAdicionais += `&valorBuscaRapida=${parametrosConsulta.valorBuscaRapida}`;
      }

      const result: any = await this.apiERP.get(`${this.endPoint}${parametrosAdicionais}`);
      const listaPaginada = {} as IListaPaginada;
      listaPaginada.metaData = JSON.parse(result.headers['x-paginacao']);
      listaPaginada.dados = result.data;
      return listaPaginada;
    }

    public async obterCreditosPessoaAberto(codigoEmpresa: number, pessoas: number[]): Promise<IDTOTituloFinanceiro[]> {
      const parametrosConsulta = {} as IParametrosConsulta;
      parametrosConsulta.empresas = [codigoEmpresa];
      parametrosConsulta.ordenacao = Array<string>();
      parametrosConsulta.ordenacao.push('DataVencimento|ASC');
      parametrosConsulta.qtdeRegistrosTotal = 999999999;
      parametrosConsulta.qtdeRegistrosPagina = 999999999;

      const filtros = [] as IFiltroGenerico[];

      const filtroStatus = {} as IFiltroGenerico;
      filtroStatus.identificador = 'Status';
      filtroStatus.tipoDado = ETipoDado.Inteiro;
      filtroStatus.operador = EOperadorLogico.E;
      filtroStatus.condicao = ECondicao.EstaEntre;
      filtroStatus.valores = [];
      filtroStatus.valores.push(String(EStatusTituloFinanceiro.Pendente));
      filtroStatus.valores.push(String(EStatusTituloFinanceiro.BaixadoParcialmente));
      filtros.push(filtroStatus);

      const filtroMeioPagamento = {} as IFiltroGenerico;
      filtroMeioPagamento.identificador = 'MeioPagamento';
      filtroMeioPagamento.tipoDado = ETipoDado.Inteiro;
      filtroMeioPagamento.operador = EOperadorLogico.E;
      filtroMeioPagamento.condicao = ECondicao.Igual;
      filtroMeioPagamento.valores = [];
      filtroMeioPagamento.valores.push(String(EMeioPagamento.CreditoLoja));
      filtros.push(filtroMeioPagamento);

      const filtroPessoa = {} as IFiltroGenerico;
      filtroPessoa.identificador = 'CodigoPessoa';
      filtroPessoa.tipoDado = ETipoDado.Longo;
      filtroPessoa.operador = EOperadorLogico.E;
      filtroPessoa.condicao = ECondicao.EstaEntre;
      filtroPessoa.valores = [];
      pessoas.forEach((codigoPessoa) => {
        filtroPessoa.valores.push(codigoPessoa.toString());
      });

      filtros.push(filtroPessoa);
      const listaPaginada = await this.buscarTitulos(parametrosConsulta, filtros);
      return listaPaginada.dados;
    }

    public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
      const parametrosEmpresas = this.apiERP.obterParametroEmpresas(parametrosConsulta.empresas);
      let parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsulta(parametrosConsulta, filtros);
      if (codigosSelecionados !== undefined) {
        parametrosAdicionais += this.apiERP.obterParametroCodigosSelecionados(codigosSelecionados);
      }
      const result: any = await this.apiERP.get(`${this.endPoint}/relatorio-padrao/${tipoArquivo}${parametrosEmpresas}${parametrosAdicionais}`);
      return result.data;
    }

    public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
      const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async obterTituloFinanceiroConsultaRapida(codigoTituloFinanceiro: number, empresas?: number[]): Promise<IItemConsultaRapida> {
      const parametrosConsultaRapida: IParametrosConsultaRapida = {
        valor: codigoTituloFinanceiro.toString(), filtrarPorCodigo: true, recursoAssociado: '',
      };

      if (UtilitarioGeral.validaLista(empresas)) {
        parametrosConsultaRapida.empresas = empresas;
      } else {
        parametrosConsultaRapida.empresas = storeSistema.getters.empresasUsuarioAutenticado();
      }

      const lista = await this.consultaRapida(parametrosConsultaRapida);
      if (UtilitarioGeral.validaLista(lista)) {
        return lista[0];
      }

      return {} as IItemConsultaRapida;
    }

    public async incluir(titulo: ITituloFinanceiro, repetir: number, sazonalidade: number): Promise<IRetornoRequisicao> {
      let parametrosAdicionais = '';
      if (repetir > 0) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `repetir=${repetir}`;
      }

      if (sazonalidade > 0) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `sazonalidade=${sazonalidade}`;
      }

      const result: any = await this.apiERP.post(`${this.endPoint}${parametrosAdicionais}`, titulo);
      return result.data;
    }

    public async alterar(titulo: ITituloFinanceiro): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${titulo.codigo}`, titulo);
      return result.data;
    }

    public async alterarEmMassa(titulos: IDTOTituloFinanceiro[]): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/alteracao-em-massa`, titulos);
      return result.data;
    }

    public async obter(codigo: number, empresa: number): Promise<ITituloFinanceiro> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async excluir(codigo: number, empresa: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async excluirEmMassa(empresa: number, codigos: number[]): Promise<IRetornoRequisicao> {
      let parametrosAdicionais = '';
      if (codigos !== undefined) {
        codigos.forEach((codigo) => {
          parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
          parametrosAdicionais += `codigos=${codigo}`;
        });
      }
      const result: any = await this.apiERP.delete(`${this.endPoint}/empresa/${empresa}${parametrosAdicionais}`);
      return result.data;
    }

    public async obterDocumentoFiscal(codigo: number, empresa: number): Promise<ITituloFinanceiroDocumentoFiscal> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/documento-fiscal/empresa/${empresa}`);
      return result.data;
    }

    public async obterCentrosCusto(codigo: number, empresa: number): Promise<IDTOTituloFinanceiroCentroCusto[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/centros-custo/empresa/${empresa}`);
      return result.data;
    }

    public async inserirAnexos(codigo: number, objeto: IDTOTituloFinanceiroAnexoInserir): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/${codigo}/anexos`, objeto);
      return result.data;
    }

    public async obterAnexos(codigo: number, empresa: number): Promise<ITituloFinanceiroAnexo[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/anexos/empresa/${empresa}`);
      return result.data;
    }

    public async removerAnexo(codigo:number, codigoTituloFinanceiroAnexo: number, codigoArquivo: number): Promise<IRetornoArquivo> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}/anexos/${codigoTituloFinanceiroAnexo}/arquivo/${codigoArquivo}`);
      return result.data;
    }

    public async obterRegistrosAtividade(codigo: number, empresa: number): Promise<IDTOTituloFinanceiroRegistroAtividade[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/registros-atividade/empresa/${empresa}`);
      return result.data;
    }

    public async obterRepasse(codigo: number, empresa: number): Promise<ITituloFinanceiroRepasse> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/repasse/empresa/${empresa}`);
      return result.data;
    }

    public async obterMarcadores(codigo: number, empresa: number): Promise<ITituloFinanceiroMarcador[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/marcadores/empresa/${empresa}`);
      return result.data;
    }

    public async consultarMarcadores(marcardor: string): Promise<string[]> {
      let parametrosAdicionais = '';
      parametrosAdicionais += `?Marcador=${marcardor}`;

      const result: any = await this.apiERP.get(`${this.endPoint}/marcadores${parametrosAdicionais}`);
      return result.data;
    }

    public async obterDetalhamentos(codigo: number, empresa: number): Promise<ITituloFinanceiroDetalhamento[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/detalhamentos/empresa/${empresa}`);
      return result.data;
    }

    public async obterDetalhamentosVariosTitulos(dados: IDTOTituloFinanceiroObterDetalhamentoVariosTitulos): Promise<IDTOTituloFinanceiroDetalhamento[]> {
      const result: any = await this.apiERP.post(`${this.endPoint}/detalhamentos/varios-titulos`, dados);
      return result.data;
    }

    public async obterBoleto(codigo: number, empresa: number): Promise<ITituloFinanceiroBoleto> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/boleto/empresa/${empresa}`);
      return result.data;
    }

    public async obterCheque(codigo: number, empresa: number): Promise<ITituloFinanceiroCheque> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/cheque/empresa/${empresa}`);
      return result.data;
    }

    public async obterValorVencerHoje(empresa: number): Promise<number> {
      const result: any = await this.apiERP.get(`${this.endPoint}/valor-vencer-hoje/empresa/${empresa}`);
      return result.data;
    }

    public async obterValorVencerEsseMes(empresa: number): Promise<number> {
      const result: any = await this.apiERP.get(`${this.endPoint}/valor-vencer-mes/empresa/${empresa}`);
      return result.data;
    }

    public async obterValorVencidos(empresa: number): Promise<number> {
      const result: any = await this.apiERP.get(`${this.endPoint}/valor-vencidos/empresa/${empresa}`);
      return result.data;
    }

    public async obterValorBaixadosHoje(empresa: number): Promise<number> {
      const result: any = await this.apiERP.get(`${this.endPoint}/valor-baixados-hoje/empresa/${empresa}`);
      return result.data;
    }

    public async ObterValorBaixadosEsseMes(empresa: number): Promise<number> {
      const result: any = await this.apiERP.get(`${this.endPoint}/valor-baixados-mes/empresa/${empresa}`);
      return result.data;
    }

    public async ObterValorBaixadosVencidos(empresa: number): Promise<number> {
      const result: any = await this.apiERP.get(`${this.endPoint}/valor-baixados-vencidos/empresa/${empresa}`);
      return result.data;
    }

    public async ObterTitulosBaixadosEsseMes(empresa: number): Promise<ITituloFinanceiro[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/baixados-mes/empresa/${empresa}`);
      return result.data;
    }

    public async ObterTitulosCalendario(empresa: number, dataInicial: string, dataFinal:string): Promise<IDTOTituloFinanceiroCalendario[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/calendario/empresa/${empresa}/dataInicial/${dataInicial}/dataFinal/${dataFinal}`);
      return result.data;
    }

    public async gerarRemessa(empresa: number, conta: number, titulos: number[]): Promise<IRetornoRequisicao> {
      let parametrosAdicionais = '';
      for (let contador = 0; contador < titulos.length; contador += 1) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Titulos=${titulos[contador]}`;
      }

      const result: any = await this.apiERP.post(`${this.endPoint}/remessa/empresa/${empresa}/conta/${conta}${parametrosAdicionais}`);
      return result.data;
    }

    public async obterRemessa(empresa: number, titulo: number): Promise<IDTORemessaPagamentos> {
      const result: any = await this.apiERP.get(`${this.endPoint}/remessa/empresa/${empresa}/titulo/${titulo}`);
      return result.data;
    }

    public async gerarRelatorioRemessa(empresa: number, titulo: number): Promise<IRetornoRelatorio> {
      const result: any = await this.apiERP.get(`${this.endPoint}/relatorio/remessa/empresa/${empresa}/titulo/${titulo}`);
      return result.data;
    }
}
export default ServicoTituloFinanceiroPagar;
